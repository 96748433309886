var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hero-banner-slide",class:{
    featured: _vm.featured,
    'bg-EXPONDO-primary': _vm.background === 'dark',
    'bg-EXPONDO-gold': _vm.background === 'light',
    'text-EXPONDO-white shadow': _vm.color === 'light',
    'text-EXPONDO-black': _vm.color === 'dark',
    'slide-image-right': _vm.imageAlignment === 'right',
    'slide-image-left': _vm.imageAlignment === 'left'
  },style:({
    'background-image': _vm.imageUrl ? ("url('" + _vm.imageUrl + "')") : null
  })},[_c('div',{staticClass:"slide-text text-word-wrap"},[_c('h2',[_vm._v(_vm._s(_vm.title))]),_vm._v(" "),(_vm.subtitle)?_c('h3',[_vm._v("\n      "+_vm._s(_vm.subtitle)+"\n    ")]):_vm._e()]),_vm._v(" "),(_vm.button.text)?_c('Button',_vm._b({staticClass:"slide-cta-button"},'Button',_vm.button,false)):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }