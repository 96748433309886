import { render, staticRenderFns } from "./HeroBannerSlide.vue?vue&type=template&id=4c350aa0&scoped=true&"
import script from "./HeroBannerSlide.vue?vue&type=script&lang=ts&"
export * from "./HeroBannerSlide.vue?vue&type=script&lang=ts&"
import style0 from "./HeroBannerSlide.vue?vue&type=style&index=0&id=4c350aa0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c350aa0",
  null
  
)

export default component.exports