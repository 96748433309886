






























import { defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';

import {
  ButtonInterface,
  ButtonDefaults
} from '~/components/atoms/Buttons/Button/ButtonTypes';
import Button from '~/components/atoms/Buttons/Button/Button.vue';

export enum SlideColorScheme {
  light = 'light',
  dark = 'dark'
}

export enum SlideImageAlignment {
  left = 'left',
  center = 'center',
  right = 'right'
}

export interface HeroBannerSlideInterface {
  title: string;
  subtitle: string;
  button: ButtonInterface;
  color: SlideColorScheme;
  background: SlideColorScheme;
  imageUrl: String;
  imageAlignment: SlideImageAlignment;
}

export default defineComponent({
  name: 'HeroBannerSlide',
  components: {
    Button
  },
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: null
    },
    button: {
      type: Object as PropType<ButtonInterface>,
      default: () => ButtonDefaults
    },
    imageUrl: {
      type: String,
      default: null
    },
    imageAlignment: {
      type: String as PropType<SlideImageAlignment>,
      default: SlideImageAlignment.center
    },
    color: {
      type: String as PropType<SlideColorScheme>,
      default: SlideColorScheme.light
    },
    background: {
      type: String as PropType<SlideColorScheme>,
      default: SlideColorScheme.dark
    },
    featured: {
      type: Boolean,
      default: false
    }
  }
});
